import { module as BaseModule } from 'modujs';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { gsap } from 'gsap';

export default class extends BaseModule {
  constructor(m) {
    super(m);

    this.events = {
      click: {
        prev: 'prev',
        next: 'next'
      }
    };
  }

  init() {
    this.swiper = new Swiper(this.el, {
      modules: [Navigation, Pagination],
      speed: 400,
      slidesPerView: 1,
      freeMode: false,
      loop: false,
      spaceBetween: 10,
      centeredSlides: 'auto',
      breakpoints: {
        1000: {
          slidesPerView: 2,
          spaceBetween: 40,
          navigation: {
            enabled: false
          }
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
          navigation: {
            enabled: true
          }
        }
      },
      pagination: {
        el: '.c-slider-dynamic__pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.c-slider-dynamic__button-next',
        prevEl: '.c-slider-dynamic__button-prev',
        enabled: false
      },
    });

    this.swiper.on('slideChange', (e) => {
      // Reset all slides to their original state
      gsap.set('.swiper-slide', { scale: 1 });

      // Get the active slide
      const activeSlide = this.swiper.slides[this.swiper.activeIndex];

      // Use GSAP to animate the active slide's elements
      gsap.to(activeSlide.querySelector('.c-slider-dynamic__box-image'), {
        scale: 1,
        duration: 0.5
      });

      // Use GSAP to animate the non-active slides' elements
      this.swiper.slides.forEach((slide, index) => {
        if (index !== this.swiper.activeIndex) {
          gsap.to(slide.querySelector('.c-slider-dynamic__box-image'), {
            scale: 0.8,
            duration: 0.5
          });
        }
      });
    });

    // Initial animation for the first slide
    gsap.to(this.swiper.slides[0].querySelector('.c-slider-dynamic__box-image'), {
      scale: 1,
      duration: 0.5
    });
  }

  prev() {
    this.swiper.slidePrev();
  }

  next() {
    this.swiper.slideNext();
  }
}
