import {
  module as BaseModule
} from 'modujs';

import Modernizr from "../vendors/modernizr";
import MLMenu from '../MLMenu';

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {
    this.initLangDropdowns();
    // this.configureBreadcrumbs();
    this.initMenu();
    this.ADANavigation();
  }

  initMenu() {
    const mlmenu = new MLMenu({
      el: '#c-mega-menu'
    });

    // mobile menu toggle
    const openMenuCtrl = document.querySelector(".c-header-navigation__button");
    const headerComponent = document.querySelector(".c-header-navigation");
    const megaMenuLevels = document.querySelectorAll('.c-mega-menu__level');

    openMenuCtrl.addEventListener("click", function () {
      const element = document.querySelector(".c-header-navigation");
      const expanded = this.getAttribute('aria-expanded') === 'true';
      this.setAttribute('aria-expanded', !expanded);

      if (element.classList.contains("has-nav-open")) {
        closeMenu();
      } else {
        openMenu();
      }
    });

    window.addEventListener('resize', () => {
      let mql = window.matchMedia("(min-width: 1000px)");

      if (headerComponent.classList.contains("has-nav-open") && mql) {
        closeMenu();
      }
    });

    function openMenu() {
      headerComponent.classList.add("has-nav-open");
      document.documentElement.classList.add("-oh");
    }

    function closeMenu() {
      headerComponent.classList.remove("has-nav-open");
      document.documentElement.classList.remove("-oh");
    }

    megaMenuLevels.forEach(megaMenuLevel => {
      megaMenuLevel.addEventListener('keydown', (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
          const focusableElements = megaMenuLevel.querySelectorAll('a, button, [tabindex]:not([tabindex="-1"])');
          const lastFocusableElement = focusableElements[focusableElements.length - 1];
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            openMenuCtrl.focus();
          }
        }
      });
    });
  }

  initLangDropdowns() {
    const langDropdowns = document.querySelectorAll('.c-header-navigation__lang-dropdown');
    const homePage = document.querySelector('.default');

    langDropdowns.forEach(langDropdown => {
      const langBtn = langDropdown.querySelector('.c-header-navigation__lang-dropdown--btn');
      const langBtnText = langBtn.querySelector('.c-header-navigation__lang-dropdown--btn-text');
      const langContent = langDropdown.querySelector('.c-header-navigation__lang-dropdown--content');
      const langLinks = langContent.querySelectorAll('li');

      const toggleLangContent = () => {
        langContent.classList.toggle('show');
        const expanded = langBtn.getAttribute('aria-expanded') === 'true';
        langBtn.setAttribute('aria-expanded', !expanded);
      };

      langContent.addEventListener('keydown', (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
          const focusableElements = langContent.querySelectorAll('a, button, [tabindex]:not([tabindex="-1"])');
          const lastFocusableElement = focusableElements[focusableElements.length - 1];
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            langContent.classList.remove('show');
          }
        }
      });

      langBtn.addEventListener('click', toggleLangContent);

      document.addEventListener('click', event => {
        const target = event.target;
        if (target !== langBtn && !langDropdown.contains(target)) {
          langContent.classList.remove('show');
        }
      });

      if (!document.querySelector('[data-page="job-detail"]') && !document.querySelector('[data-page="404"]')) {
        if (!this.hasDataLangValuesInPath()) {
          const langLinkEn = langContent.querySelector('li a[data-lang="en"]');
          this.toggleLangLinkActive(langLinkEn.parentElement, true);

          const currentPath = window.location.pathname;
          langLinks.forEach(langLink => {
            const langCode = langLink.querySelector('a').getAttribute('data-lang');
            langLink.querySelector('a').setAttribute('href', '/' + `${langCode}${currentPath}`);
          });
        } else {
          const currentPath = window.location.pathname;
          const endsWithSlash = currentPath.endsWith('/') && !homePage;
          const currentPathSegments = currentPath.split('/').filter(segment => segment !== '');
          const newPath = currentPathSegments.slice(1).join('/') + (endsWithSlash ? '/' : '');

          langLinks.forEach(langLink => {
            const langCode = langLink.querySelector('a').getAttribute('data-lang');
            langLink.querySelector('a').setAttribute('href', `/${langCode}/${newPath}`);
            if (this.isLangCodeInPath(langCode)) {
              this.toggleLangLinkActive(langLink, true);
            }
          });
        }
      }


      // const currentUrl = window.location.href;

      // function normalizeUrl(url) {
      //   return url.endsWith("/") ? url : url + "/";
      // }

      // function getLanguageFromUrl(url) {
      //   const languageRegex = /\/([a-z]{2}(?:-[a-z]{2})?)\//;
      //   const match = url.match(languageRegex);
      //   return match ? match[1] : null;
      // }

      // const normalizedCurrentUrl = normalizeUrl(currentUrl);
      // const langLinksAll = document.querySelectorAll(".c-header-navigation__lang-dropdown--content a");

      // const languageFromUrl = getLanguageFromUrl(normalizedCurrentUrl);

      // if (languageFromUrl) {
      //   let matchedLangText = null;

       
      //   langLinksAll.forEach(function (link) {
      //     const langCode = link.getAttribute("data-lang");

      //     if (languageFromUrl === langCode) {
      //       matchedLangText = link.textContent;
      //     } else if (languageFromUrl.startsWith(langCode + '-') && !matchedLangText) {
      //       matchedLangText = link.textContent;
      //     }
      //   });

      //   if (matchedLangText) {
      //     document.querySelectorAll(".c-header-navigation__lang-dropdown--btn-text").forEach(function (btnText) {
      //       btnText.textContent = matchedLangText;
      //     });
      //   }
      // }



    });
  }

  hasDataLangValuesInPath() {
    const langLinks = document.querySelectorAll('.c-header-navigation__lang-dropdown--content li');
    const pathSegments = window.location.pathname.split('/').filter(segment => segment !== ''); // Exclude empty segments

    return Array.from(langLinks).some(langLink => {
      const langCode = langLink.querySelector('a').getAttribute('data-lang');
      return pathSegments.includes(langCode);
    });
  }

  isLangCodeInPath(langCode) {
    const pathSegments = window.location.pathname.split('/').filter(segment => segment !== '');
    return pathSegments.includes(langCode);
  }

  toggleLangLinkActive(langLink, isActive) {
    if (isActive) {
      langLink.classList.add('active');
    } else {
      langLink.classList.remove('active');
    }
  }

  ADANavigation() {
    // Get all dropdown buttons and menus
    const dropdownButtons = document.querySelectorAll('.c-header-navigation__link.has-drop');
    const dropdownMenus = document.querySelectorAll('.c-header-navigation__item-submenu');

    // Function to toggle dropdown menu visibility
    const toggleDropdownMenu = (dropdownButton, dropdownMenu) => {
      const expanded = dropdownButton.getAttribute('aria-expanded') === 'true';
      // Toggle aria-expanded attribute
      dropdownButton.setAttribute('aria-expanded', !expanded);
      // Toggle dropdown menu visibility
      dropdownMenu.classList.toggle('show');
      // Toggle 'show' class on the parent node of dropdownButton
      dropdownButton.parentNode.classList.toggle('show');
    };

    // Iterate over each dropdown button and attach event listeners
    dropdownButtons.forEach((dropdownButton, index) => {
      const dropdownMenu = dropdownMenus[index];

      // Toggle dropdown menu visibility when button is clicked
      dropdownButton.addEventListener('click', () => {
        toggleDropdownMenu(dropdownButton, dropdownMenu);
      });

      // Handle keydown event for accessibility
      dropdownButton.addEventListener('keydown', (event) => {
        // Check if Enter key is pressed
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent default action
          toggleDropdownMenu(dropdownButton, dropdownMenu); // Toggle dropdown menu visibility
        }
      });

      // Close dropdown menu when last focusable element inside dropdown menu loses focus
      dropdownMenu.addEventListener('keydown', (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
          const focusableElements = dropdownMenu.querySelectorAll('a, button, [tabindex]:not([tabindex="-1"])');
          const lastFocusableElement = focusableElements[focusableElements.length - 1];
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            dropdownButton.setAttribute('aria-expanded', 'false');
            dropdownMenu.classList.remove('show');
            // Remove 'show' class from the parent node of dropdownButton
            dropdownButton.parentNode.classList.remove('show');
            const nextNavItem = dropdownButton.closest('.c-header-navigation__item').nextElementSibling;
            if (nextNavItem && nextNavItem.classList.contains('c-header-navigation__item')) {
              const nextNavItemFocusableElement = nextNavItem.querySelector('a, button, [tabindex]:not([tabindex="-1"])');
              if (nextNavItemFocusableElement) {
                nextNavItemFocusableElement.focus(); // Move focus to the first focusable element of the next navigation item
              }
            }
          }
        }
      });
    });

    // Close dropdown menus when clicking outside of them
    document.addEventListener('click', (event) => {
      dropdownButtons.forEach((dropdownButton, index) => {
        const dropdownMenu = dropdownMenus[index];
        if (!dropdownButton.contains(event.target) && !dropdownMenu.contains(event.target)) {
          dropdownButton.setAttribute('aria-expanded', 'false');
          dropdownMenu.classList.remove('show');
          // Remove 'show' class from the parent node of dropdownButton
          dropdownButton.parentNode.classList.remove('show');
        }
      });
    });
  }

  // configureBreadcrumbs() {
  //   const path = location.href.split('/').slice(3);
  //   const linkPaths = [{ "main": 'FedEx Careers', "link": '/' }];

  //   path.forEach((component, i) => {
  //     const anchorText = decodeURIComponent(component).toUpperCase().split('.')[0];
  //     const link = '/' + path.slice(0, i + 1).join('/');
  //     linkPaths.push({ "main": anchorText, "link": link });
  //   });

  //   const values = linkPaths.map(part => `<a href="${part.link}">${part.main}</a>`).join('<span style="margin:0 10px"> <svg><use xlink:href="#svg-slider-arrow-r"></use></svg></span>`);

  //   const element = document.getElementById("breadcrumb");
  //   element.innerHTML = values;
  // }

  destroy() {
    console.log("❌ [module]:destroy - Navigation");
    super.destroy();
    this.$el.off(`.${EVENT_NAMESPACE}`);
  }
}
