import { module as BaseModule } from 'modujs';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default class extends BaseModule {
  constructor(m) {
    super(m);
    
    this.options = [
      {
        // Options for first slider

        el: '.c-slider__main-0',
        pagination: {
          el: '.c-slider__pagination-0',
          clickable: true
        },
        navigation: {
          nextEl: '.c-slider__button-next-0',
          prevEl: '.c-slider__button-prev-0',
          enabled: false
        },
      },
      {
        // Options for second slider
        el: '.c-slider__main-1',
        spaceBetween: 20,
        
        pagination: {
          el: '.c-slider__pagination-1',
          clickable: true
        },
        navigation: {
          nextEl: '.c-slider__button-next-1',
          prevEl: '.c-slider__button-prev-1',
          enabled: false
        },
      },
      // Add more objects for additional sliders
      {
        // Options for second slider
        el: '.c-slider__main-2',
        spaceBetween: 20,
        breakpoints: {
          1000: {
            slidesPerView: 1,
            autoHeight: false,
            navigation: {
              enabled: false
            }
          },
          1200: {
            slidesPerView: 2,
            autoHeight: false,
            navigation: {
              enabled: true
            }
          }
        },
        
        pagination: {
          el: '.c-slider__pagination-2',
          clickable: true
        },
        navigation: {
          nextEl: '.c-slider__button-next-2',
          prevEl: '.c-slider__button-prev-2',
          enabled: false
        },
      },
      {
        //logos show 4
        el: '.c-slider__main-3',
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerGroup:2,
        breakpoints: {
          1000: {
            slidesPerView: 2,
            autoHeight: false,
            slidesPerGroup:2,
            navigation: {
              enabled: false
            }
          },
          1200: {
            slidesPerView: 5,
            autoHeight: false,
            slidesPerGroup:5,
            navigation: {
              enabled: true
            }
          }
        },
        
        pagination: {
          el: '.c-slider__pagination-3',
          clickable: true,
          dynamicBullets: false
            
        },
        navigation: {
          nextEl: '.c-slider__button-next-3',
          prevEl: '.c-slider__button-prev-3',
          enabled: false
        },
        
      },
      {
        //logos show 1
        el: '.c-slider__main-4',
        spaceBetween: 20,
        breakpoints: {
          1000: {
            slidesPerView: 1,
            autoHeight: false,
            navigation: {
              enabled: false
            }
          },
          1200: {
            slidesPerView: 1,
            autoHeight: false,
            navigation: {
              enabled: true
            }
          }
        },
        
        pagination: {
          el: '.c-slider__pagination-4',
          clickable: true
        },
        navigation: {
          nextEl: '.c-slider__button-next-4',
          prevEl: '.c-slider__button-prev-4',
          enabled: false
        },
        
      },
    ];
    this.swipers = [];

    this.events = {
      click: {
        prev: 'prev',
        next: 'next'
      }
    };
  }

  init() {
    this.options.forEach((opts, index) => {
      const swiper = new Swiper(`.c-slider__main-${index}`, {
        modules: [Navigation, Pagination],
        speed: 400,
        slidesPerView: 1,
        freeMode: false,
        spaceBetween: 30,
        autoHeight: true,
        breakpoints: {
          1000: {
            slidesPerView: 2,
            autoHeight: false,
            navigation: {
              enabled: false
            }
          },
          1200: {
            slidesPerView: 3,
            autoHeight: false,
            navigation: {
              enabled: true
            }
          }
        },
        pagination: {
          el: `.c-slider__pagination-${index}`,
          clickable: true
        },
        navigation: {
          nextEl: `.c-slider__button-next-${index}`,
          prevEl: `.c-slider__button-prev-${index}`,
          enabled: false
        },
        ...opts // Merge with custom options for this slider
      });

      swiper.on('slideChange', () => {
        const el = document.querySelector(`.c-slider__main-${index}`);

        if (swiper.isEnd) {
          el.classList.add('is-end');
        } else {
          el.classList.remove('is-end');
        }

        if (swiper.isBeginning) {
          el.classList.add('is-beginning');
        } else {
          el.classList.remove('is-beginning');
        }
      });

      this.swipers.push(swiper);
    });
  }

  prev(index) {
    this.swipers[index].slidePrev();
  }

  next(index) {
    this.swipers[index].slideNext();
  }
}
