import { module as BaseModule } from 'modujs';

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {
    const s = this.settings();
    this.bindEvents(s);
  }

  settings() {
    const arrow = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 86" width="86" height="86">
    <path d="M5.907,21.004c-1.352-1.338-3.542-1.338-4.894,0c-1.35,1.336-1.352,3.506,0,4.844l39.54,39.15
      c1.352,1.338,3.542,1.338,4.894,0l39.54-39.15c1.351-1.338,1.352-3.506,0-4.844c-1.352-1.338-3.542-1.338-4.894-0.002L43,56.707
      L5.907,21.004z" />
  </svg>
`;
    return {
      content: document.querySelectorAll('.js-read-more'),
      originalContentArr: [],
      truncatedContentArr: [],
      moreLink: `READ MORE`,
      lessLink: "Close",
    };
  }

  bindEvents(s) {
    this.truncateText(s);
  }

  countWords(str) {
    return str.split(/\s+/).length;
  }

  ellipseContent(str, wordsNum) {
    return str.split(/\s+/).slice(0, wordsNum).join(' ') + '...';
  }

  truncateText(s) {
    const content = s.content;

    for (let i = 0; i < content.length; i++) {
      const originalContent = content[i].innerHTML;
      const numberOfWords = content[i].dataset.rmWords;
      const truncateContent = this.ellipseContent(originalContent, numberOfWords);
      const originalContentWords = this.countWords(originalContent);

      s.originalContentArr.push(originalContent);
      s.truncatedContentArr.push(truncateContent);

      if (numberOfWords < originalContentWords) {
        content[i].innerHTML = s.truncatedContentArr[i];
        this.createLink(i, s);
      }
    }

    this.handleClick(content, s);
  }

  createLink(index, s) {
    const linkWrap = document.createElement('span');

    linkWrap.className = 'read-more__link-wrap';

    linkWrap.innerHTML = `<a id="read-more_${index}" class="read-more__link o-button -link" style="cursor:pointer;"><span class="o-button__inner"><span class="o-button__label">${s.moreLink}</span><span class="o-button__icon"><svg>
    <use xlink:href="#svg-arrow"></use></svg></span></span></a>`;

    // Insert created link
    s.content[index].parentNode.insertBefore(linkWrap, s.content[index].nextSibling);
  }

  handleClick(content, s) {
    const readMoreLink = document.querySelectorAll('.read-more__link');

    for (let j = 0, l = readMoreLink.length; j < l; j++) {
      readMoreLink[j].addEventListener('click', function () {
        const moreLinkID = this.getAttribute('id');
        let index = moreLinkID.split('_')[1];

        content[index].classList.toggle('is-expanded');

        if (this.dataset.clicked !== 'true') {
          content[index].innerHTML = s.originalContentArr[index];
          this.innerHTML = `<span class="o-button__inner"><span class="o-button__label">${s.lessLink}</span></span>`;
          this.dataset.clicked = true;
        } else {
          content[index].innerHTML = s.truncatedContentArr[index];
          this.innerHTML = `<span class="o-button__inner"><span class="o-button__label">${s.moreLink}</span><span class="o-button__icon"><svg>
          <use xlink:href="#svg-arrow"></use></svg></span></span>`;
          this.dataset.clicked = false;
        }
      });
    }
  }

  destroy() {
    // Clean up any event listeners or other resources here
  }
}


