import { module as BaseModule } from 'modujs';

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {
    // Check if the consent cookie is already set
    if (!this.getCookie('pixel_consent')) {
      // Show the cookie consent banner
      const cookieConsentElement = document.querySelector('.c-cookie-consent');
      if (cookieConsentElement) {
        cookieConsentElement.classList.add('is-show');
      }
    }

    // Set up the event listener for the accept button
    const acceptButton = document.querySelector('.js-accept-cookies');
    if (acceptButton) {
      acceptButton.addEventListener('click', this.handleAcceptCookies.bind(this));
    }
  }

  handleAcceptCookies() {
    // Set the cookie to expire in two weeks
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 14);
    document.cookie = `pixel_consent=true; expires=${expiryDate.toUTCString()}; path=/`;

    // Hide the cookie consent banner
    const cookieConsentElement = document.querySelector('.c-cookie-consent');
    if (cookieConsentElement) {
      cookieConsentElement.classList.remove('is-show');
    }
  }

  getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  destroy() {
    super.destroy();
  }
}
