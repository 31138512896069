import { module as BaseModule } from 'modujs';
import { gsap } from 'gsap';

// data-module-timeline="background"
// data-timeline-from='{"backgroundColor":"white"}'
// data-timeline-to='{"backgroundColor":"black"}'
// 
// CONDITION
// You can set a condition with this.condition, and set data-timeline-condition
// if condition is true, 
// - You can add data-timeline-from-condition, and data-timeline-to-condition
// - Or disabled the timeline  with data-timeline-condition-disabled

export default class extends BaseModule {
  constructor(m) {
        super(m);
    }

    init() {
        this.isMobile = typeof this.getData('mobile') == 'string';

        if(window.isMobile && !this.isMobile) return;
        
        this.condition = window.innerWidth < window.innerHeight;
        
        this.hasCondition = typeof this.getData('condition') == 'string';
        this.disabledIfCondition = typeof this.getData('condition-disabled') == 'string';        
        
        if(this.hasCondition) {
            this.bindResize = this.resize.bind(this);
            window.addEventListener('resize', this.bindResize);    
        }

        this.createTimeline();
    }

    createTimeline() {
        this.from = JSON.parse(this.getData('from'));
        this.to = JSON.parse(this.getData('to'));

        if(this.hasCondition && this.condition) {
            this.from = JSON.parse(this.getData('from-condition'));
            this.to = JSON.parse(this.getData('to-condition'));
        }

        this.tl = new gsap.timeline();
        this.tl.pause();
        this.tl.fromTo(this.el,{
            ...this.from
        }, {
            ...this.to,
            ease: 'none',
            duration: 1
        });
    }
    
    progress({progress, isReversed}) {
        let relativeProgress = progress;
        if(isReversed) relativeProgress = 1-progress;
        
        if(this.tl != undefined) {
            this.tl.progress(relativeProgress);
        }
    }

    resize() {
        if(window.isMobile) return;
        
        let oldCondition = this.condition;

        this.condition = window.innerWidth < window.innerHeight;

        if(oldCondition != this.condition) {
            if(this.disabledIfCondition && this.condition) {
                this.tl = undefined;
            }
            if(!this.condition || (!this.disabledIfCondition && this.condition)) {
                this.tl = undefined;
                this.createTimeline();
            }
        }
    }

    destroy() {
        window.removeEventListener('resize', this.bindResize);
    }
}
