import { module as BaseModule } from 'modujs';
import gsap from 'gsap';

const MAP_CONFIG = {
  insets: [
    {
      width: 900,
      top: 0,
      height: 440.70631074413296,
      bbox: [
        { y: -12671671.123330014, x: -20004297.151525836 },
        { y: 6930392.025135122, x: 20026572.39474939 },
      ],
      left: 0,
    },
  ],
  height: 440.70631074413296,
  projection: {
    type: 'mill',
    centralMeridian: 11.5,
  },
  width: 900.0,
};

const CONTINENT_MARKERS = [
  {
    key: 1,
    latLng: [50, -100],
    name: 'North America',
    countries: ['CA', 'MX', 'US'],
    linkID: 'us',
    default: true,
  },
  {
    key: 2,
    latLng: [-20, -60],
    name: 'Latin America & The Caribbean',
    countries: ['AI', 'AG', 'AW', 'BS', 'BB', 'BM', 'VG', 'KY', 'CW', 'DM', 'DO', 'GD', 'GP', 'HT', 'JM', 'MQ', 'MS', 'PR', 'SX', 'KN', 'LC', 'VC', 'TT', 'TC', 'VI', 'AR', 'BZ', 'BO', 'BR', 'CL', 'CO', 'CR', 'EC', 'SV', 'GF', 'GT', 'GY', 'HN', 'NI', 'PA', 'PY', 'PE', 'SR', 'UY', 'VE'],
    linkID: 'lac',
    default: false,
  },
  {
    key: 3,
    latLng: [50, 30],
    name: 'Europe',
    countries: ['AL', 'AM', 'AT', 'AZ', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'GE', 'DE', 'GR', 'HU', 'IE', 'IT', 'KZ', 'LV', 'LT', 'MD', 'ME', 'NL', 'MK', 'NO', 'PL', 'PT', 'RO', 'RU', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'TR', 'UA', 'GB'],
    linkID: 'europe',
    default: false,
  },
  {
    key: 4,
    latLng: [30, 100],
    name: 'Asia Pacific, Middle East, Africa',
    countries: ['AO', 'BJ', 'BW', 'BF', 'BI', 'CM', 'CV', 'TD', 'CI', 'DJ', 'ER', 'SZ', 'ET', 'GA', 'GM', 'GH', 'GN', 'KE', 'LS', 'LR', 'LY', 'MG', 'MW', 'ML', 'MR', 'MU', 'MA', 'MZ', 'NA', 'NE', 'NG', 'RW', 'SN', 'SC', 'ZA', 'TZ', 'TG', 'TN', 'UG', 'ZM', 'ZW', 'AF', 'BD', 'BT', 'CN', 'HK', 'IN', 'ID', 'JP', 'KG', 'MO', 'MY', 'MV', 'NP', 'PK', 'PH', 'SG', 'KR', 'LK', 'TW', 'TH', 'TL', 'TO', 'UZ', 'VN', 'BH', 'EG', 'IQ', 'IL', 'JO', 'KW', 'LB', 'OM', 'QA', 'SA', 'AE', 'AU', 'CK', 'PF', 'NC', 'NZ'],
    linkID: 'amea',
    default: false,
  },
];

export default class extends BaseModule {
  constructor(m) {
    super(m);
    this.worldMap = new WorldMap();
    this.dragging = false;
    this.offsetX = 0;
    this.offsetY = 0;
  }

  init() {
    this.worldMap.setMarkers(CONTINENT_MARKERS);
    this.worldMap.createMarkers();
    this.onMarkerClick();
    this.onMarkerLinkHover();
    this.initialSelect();
    this.windowResize();
    this.worldMap.updateSize();
    window.addEventListener('resize', this.windowResize.bind(this)); 

    super.init();
  }

  destroy() {
    window.removeEventListener('resize', this.windowResize.bind(this));

    super.destroy();
  }


  
  onMarkerClick() {
    const markers = document.querySelectorAll('circle.world-map-marker');
    markers.forEach((marker) => {
      marker.addEventListener('click', (e) => {
        const clickedEle = e.target;
        let key = clickedEle.getAttribute('data-index');
        key = parseInt(key, 10);
        const obj = CONTINENT_MARKERS.find((marker) => marker.key === key);
        this.selectMarkerOnGraph(obj);
      });
    });
  }

  onMarkerLinkHover() {


    const continentLinks = document.querySelectorAll('.c-continent-links a');
    continentLinks.forEach((link) => {
      link.addEventListener('mouseenter', (e) => {

        continentLinks.forEach((link) => {
          link.classList.remove('selected');
        });
        // Add the 'active' class to the currently hovered link
        e.target.classList.add('selected');

        
        const linkID = e.target.getAttribute('id');
        const obj = CONTINENT_MARKERS.find((marker) => marker.linkID === linkID);
        this.toggleMarkerSelection(obj);


      });
      link.addEventListener('mouseleave', () => {
         // Remove the 'active' class when mouse leaves the link
      link.classList.remove('selected');
        this.clearSelection();
      });
    });
  }

  toggleMarkerSelection(obj) {
    const mainSection = document.querySelector('.c-world-map__section');
    const vectorGraphPaths = mainSection.querySelectorAll('.c-world-map-graph-vector path');
    const markerWrap = mainSection.querySelector('.marker-wrap');
    vectorGraphPaths.forEach((path) => {
      if (obj.countries.includes(path.getAttribute('data-code'))) {
        path.classList.toggle('selected');
      }
    });
  }

  clearSelection() {
    const markers = document.querySelectorAll('circle.world-map-marker');
    markers.forEach((marker) => {
      marker.classList.remove('selected');
    });
    const selectedOuterCircle = document.querySelector('.marker-wrap circle.selected-outer-circle');
    if (selectedOuterCircle) {
      selectedOuterCircle.remove();
    }
    const vectorGraphPaths = document.querySelectorAll('.c-world-map-graph-vector path');
    vectorGraphPaths.forEach((path) => {
      path.classList.remove('selected');
    });
  }


  windowResize() {
    const mainSection = document.querySelector('.c-world-map__section');
    const windowWidth = mainSection.querySelector('.c-world-map__container').clientWidth - 30;
    const defaultWidth = 900;
    const calculatedWidthHeight = windowWidth / defaultWidth;
    const vectorGraphEle = mainSection.querySelector('.c-world-map-graph-vector');
    vectorGraphEle.style.transform = `scale(${calculatedWidthHeight}, ${calculatedWidthHeight})`;
    if (vectorGraphEle) {
      const parentEle = mainSection.querySelector('.c-world-map__svg');
      parentEle.classList.remove('d-none');
      parentEle.style.height = `${vectorGraphEle.getBoundingClientRect().height}px`;
    }
    const marker = mainSection.querySelector('.world-map-marker.selected-outer-circle');
    const radius = 10; 
    marker.style.r = radius;
  }
  selectMarkerOnGraph(obj) {
    const mainSection = document.querySelector('.c-world-map__section');
    const vectorGraphPaths = mainSection.querySelectorAll('.c-world-map-graph-vector path');
    const markerWrap = mainSection.querySelector('.marker-wrap');

    
    const markers = mainSection.querySelectorAll('.world-map-marker');
    markers.forEach((marker) => {
      marker.classList.remove('selected');
    });

    const selectedOuterCircle = mainSection.querySelector('.marker-wrap circle.selected-outer-circle');
    if (selectedOuterCircle) {
      selectedOuterCircle.remove();
    }

    vectorGraphPaths.forEach((path) => {
      path.classList.remove('selected');
    });

    const selectedCountries = [];
    vectorGraphPaths.forEach((path) => {
      if (obj.countries.includes(path.getAttribute('data-code'))) {
        selectedCountries.push(path);
      }
    });

    
    const marker = mainSection.querySelector(`.world-map-marker[data-index="${obj.key}"]`);
    marker.classList.add('selected');

    
    const outerCircleEle = mainSection.querySelector('.marker-template circle.selected-outer-circle').cloneNode(true);
    const radius = 10; 
    outerCircleEle.setAttribute('data-index', obj.key);
    outerCircleEle.setAttribute('cx', marker.getAttribute('cx'));
    outerCircleEle.setAttribute('cy', marker.getAttribute('cy'));
    outerCircleEle.style.r = radius;
    markerWrap.appendChild(outerCircleEle);

    selectedCountries.forEach((country) => {
      country.classList.add('selected');
    });

    

  }

  initialSelect() {
    const obj = CONTINENT_MARKERS.find((marker) => marker.default);
    if (obj) {
      this.selectMarkerOnGraph(obj);
    }

  }
}

class WorldMap {
  constructor() {
    this.markers = [];
    this.transX = 0;
    this.transY = 0;
    this.scale = 1;
    this.baseTransX = 0;
    this.baseTransY = 0;
    this.baseScale = 1;
    this.width = 0;
    this.height = 0;
    this.defaultWidth = MAP_CONFIG.width;
    this.defaultHeight = MAP_CONFIG.height;
    this.container = document.querySelector('.c-world-map-graph-vector');

    const map = this;
    this.onResize = function () {
      map.updateSize();
    };
    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(map.onResize, 20);
    });
    this.updateSize();
  }

  setMarkers(markers) {
    this.markers = markers;
  }

  updateSize() {
    this.width = this.container.getBoundingClientRect().width;
    this.height = this.container.getBoundingClientRect().height;
    this.resize();
    this.applyTransform();
  }

  resize() {
    const curBaseScale = this.baseScale;
    if (this.width / this.height > this.defaultWidth / this.defaultHeight) {
      this.baseScale = this.height / this.defaultHeight;
      const tempVal = this.width - this.defaultWidth * this.baseScale;
      this.baseTransX = Math.abs(tempVal) / (2 * this.baseScale);
    } else {
      this.baseScale = this.width / this.defaultWidth;
      const tempVal = this.height - this.defaultHeight * this.baseScale;
      this.baseTransY = Math.abs(tempVal) / (2 * this.baseScale);
    }
    this.scale *= this.baseScale / curBaseScale;
    this.transX *= this.baseScale / curBaseScale;
    this.transY *= this.baseScale / curBaseScale;
  }

  applyTransform() {
    let maxTransX = 0;
    let maxTransY = 0;
    let minTransX = 0;
    let minTransY = 0;

    if (this.defaultWidth * this.scale <= this.width) {
      maxTransX = (this.width - this.defaultWidth * this.scale) / (2 * this.scale);
      minTransX = (this.width - this.defaultWidth * this.scale) / (2 * this.scale);
    } else {
      maxTransX = 0;
      minTransX = (this.width - this.defaultWidth * this.scale) / this.scale;
    }

    if (this.defaultHeight * this.scale <= this.height) {
      maxTransY = (this.height - this.defaultHeight * this.scale) / (2 * this.scale);
      minTransY = (this.height - this.defaultHeight * this.scale) / (2 * this.scale);
    } else {
      maxTransY = 0;
      minTransY = (this.height - this.defaultHeight * this.scale) / this.scale;
    }

    if (this.transY > maxTransY) {
      this.transY = maxTransY;
    } else if (this.transY < minTransY) {
      this.transY = minTransY;
    }
    if (this.transX > maxTransX) {
      this.transX = maxTransX;
    } else if (this.transX < minTransX) {
      this.transX = minTransX;
    }
    if (this.markers) {
      this.repositionMarkers();
    }
  }

  repositionMarkers() {
    for (let i = 0; i < this.markers.length; i += 1) {
      const point = this.getMarkerPosition(this.markers[i]);
      if (point !== false) {
        const markers = document.querySelectorAll(`.world-map-marker[data-index="${this.markers[i].key}"]`);
        markers.forEach((marker) => {
          marker.setAttribute('cx', point.x);
          marker.setAttribute('cy', point.y);
        });

        // Set the position of the associated button based on linkID
        const button = document.getElementById(this.markers[i].linkID);
        if (button) {
          button.style.position = 'absolute';
          switch (this.markers[i].linkID) {
            case 'us':
              button.style.left = `${point.x}px`; // Adjust as needed
              button.style.top = `${point.y - 90}px`; // Adjust as needed
              break;
            case 'europe':
              button.style.left = `${point.x}px`; // Adjust as needed
              button.style.top = `${point.y - 70}px`; // Adjust as needed
              break;
            case 'lac':
              button.style.left = `${point.x - 200}px`; // Adjust as needed
              button.style.top = `${point.y - 50}px`; // Adjust as needed
              break;
            case 'amea':
              button.style.left = `${point.x - 200}px`; // Adjust as needed
              button.style.top = `${point.y + 100}px`; // Adjust as needed
              break;
            default:
              break;
          }
        }

     

      
      }
    }
  }

  latLngToPoint(lat, long) {
    let point = {};
    const proj = MAP_CONFIG.projection;
    let inset = {};
    let box = [];

    let lng = long;
    if (lng < -180 + proj.centralMeridian) {
      lng += 360;
    }

    point = this.mill(lat, lng, proj.centralMeridian);

    inset = this.getInsetForPoint(point.x, point.y);
    if (inset) {
      box = inset.bbox;

      point.x = (point.x - box[0].x) / (box[1].x - box[0].x) * inset.width * this.scale;
      point.y = (point.y - box[0].y) / (box[1].y - box[0].y) * inset.height * this.scale;

      return {
        x: point.x + this.transX * this.scale + inset.left * this.scale,
        y: point.y + this.transY * this.scale + inset.top * this.scale
      };
    }
    return false;
  }

  getMarkerPosition(markerConfig) {
    if (MAP_CONFIG.projection) {
      const lat = markerConfig.latLng[0];
      const lng = markerConfig.latLng[1];
      return this.latLngToPoint(lat || 0, lng || 0);
    }
    return {
      x: markerConfig.coords[0] * this.scale + this.transX * this.scale,
      y: markerConfig.coords[1] * this.scale + this.transY * this.scale
    };
  }

  createMarkers() {
    const markerWrap = document.querySelector('.marker-wrap');
    const markerLinkContainer = document.querySelector('.c-continent-links');

    this.markers.forEach((marker) => {
      if (marker.latLng && marker.latLng.length === 2) {
        const point = this.getMarkerPosition(marker);
        const button = document.getElementById(marker.linkID);

        const circleEle = document.querySelector('.marker-template circle.world-map-marker:not(.selected-outer-circle)').cloneNode(true);
        circleEle.setAttribute('data-index', marker.key);
        circleEle.setAttribute('cx', point.x);
        circleEle.setAttribute('cy', point.y);
        markerWrap.appendChild(circleEle);

      // Set the position of the button relative to the SVG container
      if (button) {
        button.style.position = 'absolute';
        button.style.left = `${point.x}px`; // Adjust as needed
        button.style.top = `${point.y}px`; // Adjust as needed


        // Append the button to the button container
        // markerLinkContainer.appendChild(button);
      }

      }
    });
  }

  mill(lat, lng, c) {
    const radius = 6381372;
    const radDeg = Math.PI / 180;
    return {
      x: radius * (lng - c) * radDeg,
      y: -radius * Math.log(Math.tan((45 + 0.4 * lat) * radDeg)) / 0.8
    };
  }

  getInsetForPoint(x, y) {
    const configInsets = MAP_CONFIG.insets;
    let i = 0;
    let box = [];
    for (i = 0; i < configInsets.length; i += 1) {
      box = configInsets[i].bbox;
      if (x > box[0].x && x < box[1].x && y > box[0].y && y < box[1].y) {
        return configInsets[i];
      }
    }
    return {};
  }
}
