import { module as BaseModule } from 'modujs';
import { gsap } from 'gsap';
import Swiper from 'swiper';
import { FreeMode, Thumbs, Pagination } from 'swiper/modules';

export default class extends BaseModule {
    constructor(module) {
        super(module);
    
        this.swiper1 = null;
        this.swiper2 = null;

        // Bind methods to the class instance
        this.initSwiper = this.initSwiper.bind(this);
        this.destroySwiper = this.destroySwiper.bind(this);
        this.handleViewportChange = this.handleViewportChange.bind(this);
    }

    initSwiper() {
        // Initialize Swiper instances only if they don't exist
        if (!this.swiper1 && !this.swiper2) {
            // content
            this.swiper1 = new Swiper(".mySwiper", {
                modules: [FreeMode],
                spaceBetween: 10,
                slidesPerView: 1,
                freeMode: false,
                loop: true,
                allowTouchMove: false,
                watchSlidesProgress: true,
            });

            // image
            this.swiper2 = new Swiper(".mySwiper2", {
                modules: [Thumbs, Pagination],
                spaceBetween: 10,
                slidesPerView: 1,
                loop: true,
                centeredSlides: true,
                thumbs: {
                    swiper: this.swiper1,
                },
                pagination: {
                    el: ".swiper-pagination",
                    dynamicBullets: false,
                    clickable: true
                }
            });
        }
    }

    destroySwiper() {
        // Destroy Swiper instances with clearProps
        if (this.swiper1 && this.swiper2) {
            this.swiper1.destroy(true, true);
            this.swiper2.destroy(true, true);
            this.swiper1 = null;
            this.swiper2 = null;
        }
    }

    handleViewportChange() {
        // Match media to check viewport width
        const isMobile = window.matchMedia('(max-width: 999px)').matches;

        if (isMobile) {
            // Mobile view: Initialize Swiper
            this.initSwiper();
        } else {
            // Desktop view: Destroy Swiper
            this.destroySwiper();
        }
    }

    init() {
        // Initialize Swiper instances or destroy them based on viewport width
        this.handleViewportChange();

        // Listen for viewport changes
        window.addEventListener('resize', this.handleViewportChange);
    }

    // Destroy Swiper instances when module is destroyed
    destroy() {
        this.destroySwiper();
        window.removeEventListener('resize', this.handleViewportChange);
    }
}
