import { module as BaseModule } from 'modujs';
import { gsap } from 'gsap';
import Swiper from 'swiper';
import { FreeMode, Thumbs } from 'swiper/modules';

export default class extends BaseModule {
    constructor(module) {
        super(module);
        this.gridItems = document.querySelectorAll('.c-modal-cards-grid__item');
        this.mainItems = document.querySelectorAll('.c-modal-cards-main--item');
        this.closeButtons = document.querySelectorAll('.c-modal-cards-main__content-close');
        this.modalInitialized = false; // Flag to track if modal is initialized
        this.swiper1 = null;
        this.swiper2 = null;
    }

    initSwiper() {
        // Initialize Swiper for .c-modal-cards-grid--slide
        this.swiper1 = new Swiper(".c-modal-cards-grid--slide", {
            modules: [FreeMode],
            loop: true,
            spaceBetween: 30,
            slidesPerView: 3,
            freeMode: true,
            watchSlidesProgress: true,
        });

        // Initialize Swiper for .c-modal-cards-main--thumbs with navigation and thumbs
        this.swiper2 = new Swiper(".c-modal-cards-main--thumbs", {
            modules: [Thumbs],
            loop: false,
            slidesPerView: 1,
            allowTouchMove: false,
            
            thumbs: {
                swiper: this.swiper1,
            },
        });
    }

    destroySwiper() {
        // Check if Swiper instances exist and destroy them
        if (this.swiper1 && this.swiper2) {
             // Remove "swiper-slide-thumb-active" class from all slides
        const thumbs = document.querySelectorAll('.swiper-slide');
        thumbs.forEach(thumb => {
            thumb.classList.remove('swiper-slide-thumb-active');
        });

            this.swiper1.destroy();
            this.swiper2.destroy();
            this.swiper1 = null;
            this.swiper2 = null;
        }
    }

    initModal() {
        
        this.gridItems.forEach((item, index) => {
            item.addEventListener('click', () => {
                const targetModalId = item.dataset.modalTarget;
                const targetIndex = Array.from(this.mainItems).findIndex(mainItem => mainItem.dataset.modalId === targetModalId);
                if (targetIndex !== -1) {
                    this.showMainItem(targetIndex);
                }
            });
             // Add keyboard event listener for accessibility
         item.addEventListener('keydown', (event) => {
            if (event.key === 'Enter' || event.key === 'Space') {
                const targetModalId = item.dataset.modalTarget;
                const targetIndex = Array.from(this.mainItems).findIndex(mainItem => mainItem.dataset.modalId === targetModalId);
                if (targetIndex !== -1) {
                    this.showMainItem(targetIndex);
                }
            }
        });
        });

        

        this.closeButtons.forEach((button, index) => {
            button.addEventListener('click', () => {
                this.hideMainItem(index);
            });
            button.addEventListener('keydown', (event) =>{
                if(event.key === 'Enter' || event.key == 'Space') {
                    this.hideMainItem(index);
                }
            });
        });

        this.modalInitialized = true;
    }

    destroyModal() {
        // Remove event listeners from grid items and close buttons
        this.gridItems.forEach((item) => {
            item.removeEventListener('click', () => {});
            item.removeEventListener('keydown', () => {});
        });

        this.closeButtons.forEach((button) => {
            button.removeEventListener('click', () => {});
            button.removeEventListener('keydown', () => {});
        });

          // Remove any added classes or inline styles
    this.gridItems.forEach(item => {
        item.classList.remove('active'); // Remove active class from grid items
    });

    this.mainItems.forEach(item => {
        item.classList.remove('active'); // Remove active class from main items
        gsap.set(item, { autoAlpha: 1 }); // Reset opacity
    });


        this.modalInitialized = false;
    }




    init() {
         // Check if viewport width is initially less than or equal to 1000px
        if (window.innerWidth <= 999) {
            this.initSwiper();
        }
        // Check if viewport width is initially greater than 1000px
        else {
            this.initModal();
        }
       // Add event listener for window resize
       window.addEventListener('resize', () => {
        // Check if viewport width becomes less than or equal to 1000px and Swiper is not initialized
        if (window.innerWidth <= 999 && !this.swiper1 && !this.swiper2) {
            this.initSwiper();
            // If modal is initialized, destroy it
            if (this.modalInitialized) {
                this.destroyModal();
            }
        }
        // Check if viewport width becomes greater than 1000px and modal is not initialized
        else if (window.innerWidth > 999 && !this.modalInitialized) {
            
            this.initModal();
            // If Swiper is initialized, destroy it
            if (this.swiper1 && this.swiper2) {
                this.destroySwiper();
            }
        } 
        // Check if viewport width becomes greater than 1000px and modal is initialized
        // else if (window.innerWidth > 1000 && this.modalInitialized) {
        //     // If modal is initialized, destroy it
        //     this.destroyModal();
        // }

          
          
    });
    }

    showMainItem(index) {
        
        // Remove active class from all grid items
        this.gridItems.forEach(item => {
            item.classList.remove('active');
        });

        // Add active class to the clicked grid item
        this.gridItems[index].classList.add('active');

        // Hide all main items
        this.mainItems.forEach(item => {
            item.classList.remove('active');
            gsap.set(item, { autoAlpha: 0 }); // Hide with opacity
        });

        // Show the clicked main item
        this.mainItems[index].classList.add('active');
        gsap.to(this.mainItems[index], { autoAlpha: 1, duration: 0.3 });
    }

    hideMainItem(index) {
        
        // Remove active class from corresponding grid item
        const targetModalId = this.mainItems[index].dataset.modalId;
        const targetGridIndex = Array.from(this.gridItems).findIndex(gridItem => gridItem.dataset.modalTarget === targetModalId);
        if (targetGridIndex !== -1) {
            this.gridItems[targetGridIndex].classList.remove('active');
        }

        // Hide the main item
        this.mainItems[index].classList.remove('active');
        gsap.to(this.mainItems[index], { autoAlpha: 0, duration: 0.3 });
    }


    // Destroy Swiper instances when module is destroyed
    destroy() {
        this.destroySwiper();
        window.removeEventListener('resize', this.handleWindowResize);
    }
}

   

