import { module as BaseModule } from 'modujs';

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {
    var filtersButton = document.querySelector('.c-job__search-filters--btn');
    var filtersElement = document.querySelector('.c-job__search-filters');
    var resetFiltersLink = document.querySelector('.reset-filters > a:nth-child(1)');
    var resetFiltersElement = document.querySelector('.reset-filters');

    // Function to toggle filters
    function toggleFilters() {
      filtersElement.classList.toggle('show');

      // Check if filtersElement has the class "show"
      if (filtersElement.classList.contains('show')) {
        if (window.innerWidth >= 1000) {
          // Simulate a click on the first element with the class "filter-header" if it doesn't have the class "open"
          var filterHeader = filtersElement.querySelector('.filter-header');
          if (filterHeader && !filterHeader.classList.contains('open')) {
            filterHeader.click();
          }

        }
      }
    }

    if (filtersButton && filtersElement && resetFiltersLink && resetFiltersElement) {
      filtersButton.addEventListener('click', function (event) {
        event.stopPropagation(); // Stop the click event from reaching the document
        toggleFilters();
      });

      document.addEventListener('click', function (event) {
        var isFilterHeader = event.target.classList.contains('filter-header');

        // Check if the clicked element is not within filtersElement, resetFiltersElement, or filtersButton
        if (!filtersElement.contains(event.target) && !resetFiltersElement.contains(event.target) && !event.target.classList.contains('c-job__search-filters--btn')) {
          filtersElement.classList.remove('show');
        }

        // If the clicked element is "filter-header" and it already has the class "open", don't do anything
        if (isFilterHeader && event.target.classList.contains('open')) {
          return;
        }

      });

      // Update the existing event listener for the resetFiltersLink
      resetFiltersLink.addEventListener('click', function (event) {
        event.preventDefault();
        toggleFilters(); // Toggle filters when the resetFiltersLink is clicked
      });

      // Create and append the "Apply" link inside resetFiltersElement
      var applyLink = document.createElement('a');
      applyLink.href = '#'; // Set the href attribute as needed
      applyLink.innerText = 'Apply Filter';
      applyLink.classList.add('custom_close--apply'); // Add the "apply" class
      applyLink.addEventListener('click', function (event) {
        event.preventDefault();
        toggleFilters(); // Toggle filters when the "Apply" button is clicked
      });
      setTimeout(() => {
        resetFiltersElement.appendChild(applyLink);
      }, 1000); // Delay appending by 1 second (1000 milliseconds)
    }
  }

  destroy() {
    super.destroy();
    // Add any additional cleanup logic here if needed
  }
}
