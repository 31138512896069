import { module as BaseModule } from 'modujs';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';


export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {
    Fancybox.bind('[data-fancybox]', {
      //
      defaultType: "youtube"
    });
    
  }

  destroy() {
    super.destroy();
  }
}
