import {
  module as BaseModule
} from 'modujs';
import {
  gsap
} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'; // Import ScrollTrigger from gsap
gsap.registerPlugin(ScrollTrigger); // Register ScrollTrigger plugin

export default class extends BaseModule {
  constructor(module) {
    super(module);

    // Bind the methods to the instance of the class
    this.getHeightCarouselScroll = this.getHeightCarouselScroll.bind(this);

    this.clearInlineStyles = this.clearInlineStyles.bind(this);
    this.initTimeline = this.initTimeline.bind(this); // Bind the method



  }


  init() {


    // Call the getHeightCarouselScroll function initially
    this.getHeightCarouselScroll();

    // Function to refresh ScrollTrigger
    const refreshScrollTrigger = () => {
      ScrollTrigger.refresh();
    };


    // Debounced function to refresh ScrollTrigger
    let debouncedRefreshScrollTrigger;
    const debounceDelay = 100; // Adjust delay time as needed

    // Initialize MutationObserver to detect changes in the size of .c-modal-cards div
    const observer = new MutationObserver(() => {
      // Debounce the observer callback using setTimeout
      clearTimeout(debouncedRefreshScrollTrigger);
      debouncedRefreshScrollTrigger = setTimeout(refreshScrollTrigger, debounceDelay);
    });

    // Configuration for the observer
    const config = {
      attributes: true, // Listen for changes in attributes
      attributeFilter: ['style'], // Listen specifically for changes in style attribute
      subtree: true // Listen for changes in the entire subtree
    };

    // Start observing the .c-modal-cards div for changes in its size
    const modalCardsDiv = document.querySelector('.c-modal-cards');
    if (modalCardsDiv) {
      observer.observe(modalCardsDiv, config);
    }

    // this.initTimeline();
    // Initialize ScrollTrigger media match
    const mm = gsap.matchMedia();

    mm.add('(min-width: 1000px)', () => {

      // Initialize timeline when media matches
      this.initTimeline();
    });

    // Add event listener for window resize to refresh ScrollTrigger and get height
    let resizeTimer;
    window.addEventListener('resize', () => {
      this.getHeightCarouselScroll();
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
      

        // Reinitialize the timeline based on match media
        if (mm.matches) {
          this.initTimeline();
            // Refresh ScrollTrigger after a delay
        refreshScrollTrigger();
        }
      }, 100); // Adjust delay time as needed
    });



    // Add event listeners to 'a' elements within .c-sticky-scroll__content
    const links = document.querySelectorAll('.c-sticky-scroll__content a');
    links.forEach(link => {
      link.addEventListener('focus', this.clearInlineStyles);
    });


  }


  initTimeline() {
    const CarouselScrollList = document.querySelectorAll('.c-sticky-scroll__list-item');
    const count = CarouselScrollList.length;

    // Clear existing timeline
    if (this.tl) {
      this.tl.kill();

    }



    // Initialize new timeline
    this.tl = gsap.timeline({
      defaults: {
        ease: 'none'
      },
      scrollTrigger: {
        trigger: '.c-sticky-scroll__inner',
        pin: true,
        scrub: 0,
        start: 'center center',
        end: `top+=${this.heightCarouselScroll * count} center`,
      }
    });

    const CarouselScroll = gsap.utils.toArray('.c-sticky-scroll__list-item');
    const CarouselScrollBox = gsap.utils.toArray('.c-sticky-scroll__content');
    const CarouselScrollImg = gsap.utils.toArray('.c-sticky-scroll__image');

    CarouselScroll.forEach((section, i) => {
      const pos = i ? '+=0.5' : '';
      if (CarouselScrollBox[i + 1]) {
        this.tl.to(CarouselScrollBox[i], {
            opacity: 0,
            y: -100
          }, pos)
          .to(CarouselScrollImg[i], {
            opacity: 0,
            duration: 1
          }, '<')
          .to(CarouselScrollImg[i + 1], {
            opacity: 1,
            duration: 1
          }, '<')
          .fromTo(CarouselScrollBox[i + 1], {
            opacity: 0,
            y: 100
          }, {
            opacity: 1,
            y: 0,
            zIndex: 1
          }, '<+=0.5');
      }
    });
  }
  destroy() {
    // Remove event listeners from 'a' elements
    const links = document.querySelectorAll('.c-sticky-scroll__content a');
    links.forEach(link => {
      link.removeEventListener('focus', this.clearInlineStyles);
    });


    observer.disconnect();
    // Remove any event listeners or clean up tasks if necessary
  }

  getHeightCarouselScroll() {
    this.heightCarouselScroll = document.querySelector('.c-sticky-scroll__inner').clientHeight;
  }


  clearInlineStyles(event) {
    const contentDiv = event.target.closest('.c-sticky-scroll__content');
    if (contentDiv) {
      const allContentDivs = document.querySelectorAll('.c-sticky-scroll__content');
      allContentDivs.forEach(div => {
        if (div !== contentDiv) {
          div.style.opacity = '0';
        }
      });
      contentDiv.style.transform = 'none';
      contentDiv.style.opacity = '1';
    }
  }

}
