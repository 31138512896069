import { module as BaseModule } from 'modujs';
import { gsap } from 'gsap';

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {
    var Accordion = (function () {
      var toggleItems, items;

      var _init = function () {
        toggleItems = document.querySelectorAll(".c-accordion__item");
        toggleItems = Array.prototype.slice.call(toggleItems);
        items = document.querySelectorAll(".c-accordion__item--click");
        items = Array.prototype.slice.call(items);

        _addEventHandlers();
        gsap.set(items, {
          visibility: "visible",
        });
      };

      var _addEventHandlers = function () {
        items.forEach(function (element, index) {
          element.addEventListener("click", _toggleItem, false);
        });
      };

      var _toggleItem = function () {
        var parent = this.parentNode;
        var content = parent.children[1];
        

        if (!parent.classList.contains("is-active")) {
          parent.classList.add("is-active");
          gsap.set(content, {
            height: "auto",
          });
          gsap.from(content, {
            height: 0,
            duration: 0.5,
            immediateRender: false,
            ease: "Back.easeOut",
          });

          if(parent.classList.contains("-has-inner-accordion")) {
            gsap.to(content, {
              height: "auto",
              
            });

          }
        } else {
          parent.classList.remove("is-active");
          gsap.to(content, {
            height: 0,
            duration: 0.3,
            immediateRender: false,
            ease: "Power1.easeOut",
          });
        }
      };

      return {
        init: _init,
      };
    })();

    Accordion.init();
  }
}
